
import Buyahome from "../components/Buyahome";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import Testimonials from "../components/Testimonials";
import Upcomingproject from "../components/Upcomingproject";
import Articals from "../components/Articals";
import SearchBox from "../components/Searchbox";
import Categories from "../components/Ctaegories";


function Home() {

  return (
    <>
      <Header />
      <SearchBox />
      <Categories />
      <Buyahome />
      <Upcomingproject />
      <Articals />
      <Testimonials />
      <Navbar />
    </>
  );
}

export default Home;