import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import Logincarusel from '../components/Logincarusel';

function Register() {
  const [phone_no, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [touchedFields, setTouchedFields] = useState({
    name: false,
    email: false,
    phone_no: false,
    password: false,
  });
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === 'phone_no') {
      if (/^\d{0,10}$/.test(value)) {
        setPhoneNumber(value);
        setIsButtonDisabled(
          value.length !== 10 || !validateEmail(email) || !name || password.length < 6
        );
      }
    } else if (name === 'email') {
      setEmail(value);
      setIsButtonDisabled(
        !validateEmail(value) || phone_no.length !== 10 || !name || password.length < 6
      );
    } else if (name === 'name') {
      setName(value);
      setIsButtonDisabled(
        !validateEmail(email) || phone_no.length !== 10 || !value || password.length < 6
      );
    } else if (name === 'password') {
      setPassword(value);
      setIsButtonDisabled(
        !validateEmail(email) || phone_no.length !== 10 || !name || value.length < 6
      );
    }
  };

  const handleBlur = (field) => {
    setTouchedFields({ ...touchedFields, [field]: true });
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleSendOTP = async () => {
    // Check if the fields are filled with valid information
    if (!name || !validateEmail(email) || phone_no.length !== 10 || password.length < 6) {
      toast.error('Please fill in all fields with valid information.');
      return;
    }

    try {
      // Make the POST request to the registration API
      const response = await axios.post(
        'https://kamaupoot.microcominternationals.com/public/api/register',
        {
          name,
          email,
          password,
          phone_no,
        }
      );

      // If registration is successful, store the token and phone number in sessionStorage
      if (response.data.token) {
        sessionStorage.setItem('phone_no', phone_no);
        sessionStorage.setItem('token', response.data.token); // Store token
        console.log(response.data.token)

        // Display success message and navigate to the login page
        toast.success('Registered successfully.');
        navigate('/login');
      } else {
        // Handle cases where token is missing in the response
        toast.error('Registration failed. Token not received.');
      }
    } catch (error) {
      // Handle registration failure, display an error message from the API response
      const errorMessage =
        error.response?.data?.message || 'Registration failed. Please try again.';
      toast.error(errorMessage);
    }
  };

  return (
    <section className="flex flex-col min-h-screen">
      <div className="h-60 flex flex-col items-center top-0">
        <Logincarusel />
      </div>
      <div className='mx-3 mb-3 mt-auto'>
        <h1 className='text-2xl font-rubik font-bold text-center mb-3'>Register</h1>
        {/* <p className='text-center font-sm text-gray-500 font-semibold mb-3'>Register</p> */}
        <form className="space-y-5">
          <div>
            <input
              id="name"
              name="name"
              value={name}
              onChange={handleInputChange}
              onBlur={() => handleBlur('name')}
              className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
              type="text"
              placeholder="Enter your name"
              required
              autoFocus
            />
            {touchedFields.name && !name && (
              <p className="text-red-500 text-sm">Name is required</p>
            )}
          </div>
          <div>
            <input
              id="email"
              name="email"
              value={email}
              onChange={handleInputChange}
              onBlur={() => handleBlur('email')}
              className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
              type="email"
              placeholder="Email"
              required
            />
            {touchedFields.email && !validateEmail(email) && (
              <p className="text-red-500 text-sm">Invalid email address</p>
            )}
          </div>
          <div>
            <input
              id="phone_no"
              name="phone_no"
              value={phone_no}
              onChange={handleInputChange}
              onBlur={() => handleBlur('phone_no')}
              className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
              type="text"
              placeholder="Phone no"
              required
            />
            {touchedFields.phone_no && phone_no.length !== 10 && (
              <p className="text-red-500 text-sm">Mobile number must be 10 digits</p>
            )}
          </div>
          <div>
            <input
              id="password"
              name="password"
              value={password}
              onChange={handleInputChange}
              onBlur={() => handleBlur('password')}
              className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
              type="password"
              placeholder="Enter your password"
              required
              maxLength={10}
            />
            {touchedFields.password && password.length < 6 && (
              <p className="text-red-500 text-sm">Password must be at least 6 characters</p>
            )}
          </div>
          <div>

            <button
              type="button"
              onClick={handleSendOTP}
              disabled={isButtonDisabled}
              className="inline-flex w-full items-center justify-center rounded-md bg-btncolor px-3.5 py-2.5 font-semibold leading-7 text-white hover:bg-btncolor font-rubik"
            >
              Register
            </button>

          </div>
        </form>
        <p className='text-xs mt-1 text-center'>Already have an account? <Link to='/login'> <span>Login!</span></Link></p>
      </div>
      <div>
        {/* <p className='text-xs font-rubik text-gray-600 text-center'>By continuing, you agree to our</p> */}
        <div className='flex gap-3 justify-center mb-3'>
          <Link to="/t&c">  <p className='font-rubik text-gray-600 text-center' style={{ fontSize: "11px" }}>Terms & condition  </p></Link>
          <Link to="/policy">   <p className='font-rubik text-gray-600 text-center' style={{ fontSize: "11px" }}>Privacy Policy</p></Link>
        </div>
      </div>
      <ToastContainer autoClose={300} />
    </section>
  );
}

export default Register;
