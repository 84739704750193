
import { TiBell } from "react-icons/ti";
// import { image } from "../../public/assets/logo/Kamaaupoot.png"

function Header() {
  return (
    <div className="flex shadow-x w-full bg-white fixed top-0 z-10">
      <div className="flex mx-2 justify-between w-full ">
        <div className=" float-start ">
          <img src="assets\logo\Kamaaupoot.png" alt="" width="55%" />
        </div>
        <div className="content-center">
          <TiBell className="text-3xl me-3" />
        </div>
      </div>
    </div>
  );
}

export default Header;
