import Headerb from "../components/Headerb";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import { IoLocationOutline } from "react-icons/io5";
import { MdCurrencyRupee } from "react-icons/md";

function Savedproperty() {
  return (
    <>
      <Headerb />
      <h1 className="mt-20 ms-3 font-rubik text-2xl font-bold">Saved Property</h1>

      <div className="relative mx-1 border border-gray-400 rounded-lg mt-5 ">
        <Link to="#" className="relative inline-block w-full transform transition-transform duration-300 ease-in-out">
          <div className="rounded-lg">
            <div className="relative flex h-60 justify-center overflow-hidden rounded-lg">
              <div className="w-full transform transition-transform duration-500 ease-in-out hover:scale-110">
                <img src="assets\images\banner.jpg" alt="" />
              </div>
            </div>

            <div className="mx-2 mb-2">
              <div className="mt-4 ">
                <div className="flex items-center">
                  <div className="relative">
                    <h1 className="text-xl font-semibold font-rubik line-clamp-1">VibrantVoyage Ventures</h1>
                    <div className="mt-1 flex items-center text-sm text-gray-600">
                      <IoLocationOutline className="mr-1 text-lg" />
                      <p className="text-sm">Jaipur</p>
                    </div>
                    <div className="flex items-center text-sm text-gray-600">
                      <MdCurrencyRupee className="mr-1 text-custom-pink text-lg" />
                      <p className="text-custom-pink text-lg">200 L</p>
                    </div>
                    <p className="text-sm text-gray-600">1000 Sq. Ft.</p>
                    <div className="flex items-center text-sm text-gray-600">
                      <p className="text-custom-pink text-lg font-extrabold font-serif">Expected Return (Annual) :10-20%</p>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div className="flex items-center justify-center p-4 border-t space-x-2 bg-btncolor/90">
              <Link to="/propertydetail"> <button className="ml-1 font-rubik font-bold text-white ">View Property</button></Link>
            </div>
          </div>
        </Link>
      </div>
      <Navbar />
    </>
  );
}

export default Savedproperty;