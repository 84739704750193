import { useEffect, useRef, useState } from "react";
import Headerb from "../components/Headerb";
import { FaRegBookmark, FaBookmark } from 'react-icons/fa';
import { MdOutlineCurrencyRupee } from "react-icons/md";
import PieChart from "../components/Piechart";
import { scrollToTop } from "../utils/scrollToTop";
import { IoShareSocialSharp } from "react-icons/io5";
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from "react-router-dom";
import { Bar } from "react-chartjs-2";
import { FaFilePdf } from 'react-icons/fa';
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { GiReturnArrow } from "react-icons/gi";
import RevenueChart from "../components/Revenuechart";




function Propertydetail() {
  const [isPDFopen, setPDFopen] = useState(false)
  const [bookmarked, setBookmarked] = useState(false);
  const handleBookmarkClick = () => {
    setBookmarked(!bookmarked);
  };


  const topele = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (topele.current) {
      scrollToTop(topele);
    }
  }, []);

  const [property, setProperty] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: document.title,
          text: "Check out this awesome page!",
          url: window.location.href,
        });
        console.log("Page shared successfully");
      } catch (error) {
        console.error("Error sharing the page", error);
      }
    } else {
      alert("Web Share API is not supported in this browser.");
    }
  };

  useEffect(() => {
    const fetchPropertyDetails = async () => {
      const propertyId = sessionStorage.getItem('property_id');

      if (!propertyId) {
        setError('Property ID not found in session storage.');
        setLoading(false);
        return;
      }

      try {
        const response = await axios.post(
          'https://kamaupoot.microcominternationals.com/public/api/property-details',
          { id: propertyId }
        );
        const propertyData = response.data.properties[0];
        if (propertyData) {
          setProperty(propertyData);
        } else {
          setError('Property not found.');
        }
      } catch (err) {
        setError('Failed to fetch property details.');
      } finally {
        setLoading(false);
      }
    };

    fetchPropertyDetails();
  }, []);


  const galleryImages = property?.images?.map((img) => ({
    original: `https://kamaupoot.microcominternationals.com/public/${img.images}`,
    thumbnail: `https://kamaupoot.microcominternationals.com/public/${img.images}`,
  })) || [];


  const handlePdfOpen = () => {
    if (property && property.pdf) {
      const pdfUrl = `https://kamaupoot.microcominternationals.com/public/${property.pdf}`;
      window.open(pdfUrl, '_blank', 'noopener,noreferrer');
    } else {
      toast.error("PDF not available.");
    }
  };

  // console.log(property)
  const revenuePlan = property && property.revenue_plan ? JSON.parse(property.revenue_plan) : [];


  const years = revenuePlan.map(item => item.year);
  const revenues = revenuePlan.map(item => item.revenue / 100);

  const data = {
    labels: years,
    datasets: [
      { label: "Returns", data: revenues, backgroundColor: "rgba(75, 192, 192, 0.6)" },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        max: 1,
        ticks: { stepSize: 0.2, callback: value => `${value * 100}%` }
      }
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: tooltipItem => `${tooltipItem.dataset.label}: ${tooltipItem.raw * 100}%`
        }
      }
    }
  };



  const handleBookACall = async () => {
    const token = sessionStorage.getItem('token');
    const propertyId = sessionStorage.getItem('property_id');

    if (!token) {
      navigate('/register');
      return;
    }

    if (!propertyId) {
      toast.error('Property ID not found.');
      return;
    }

    try {
      const response = await axios.post(
        'https://kamaupoot.microcominternationals.com/public/api/property-enquiry',
        { property_id: propertyId },
        {
          headers: {
            'Authorization': `${token}`,
          }
        }
      );
      toast.success(response.data.message || 'Enquiry submitted successfully!');
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(error.response.data.error || 'Failed to submit enquiry.');
      } else {
        toast.error('Failed to submit enquiry.');
      }
    }
  };

  if (loading) return <div><p className="text-center mt-10">Loading...</p></div>;
  if (error) return <div>{error}</div>;

  // Ensure property exists before accessing its properties
  if (!property) {
    return <div>Property data not available.</div>;
  }

  return (
    <>

      <p ref={topele}>.</p>
      <Headerb />
      <div className="max-w-sm mx-auto bg-white rounded-t-xl overflow-hidden md:max-w-2xl mt-10 ">
        <div className="relative">
          <img
            className="w-full object-cover"
            src={`https://kamaupoot.microcominternationals.com/public/${property.images[property.images.length - 1]?.images}`}
            alt="Property"
            style={{ height: "500px" }}
          />

          <div className="absolute inset-0 bg-black opacity-10"></div>
          <div className="absolute top-0 left-0 m-4 bg-black font-rubik opacity-50 text-white text-sm p-2 rounded">Open for sale</div>
          <div className="absolute top-0 right-0 m-4">
            <button onClick={handleBookmarkClick}>
              {bookmarked ? (
                <FaBookmark className="h-6 w-6 text-white" />
              ) : (
                <FaRegBookmark className="h-6 w-6 text-white" />
              )}
            </button>
          </div>
          <div className="absolute bottom-0 left-0 mb-10 ms-4">
            <h1 className="text-3xl font-rubik font-semibold text-white">{property.title}</h1>
            <p className="text-lg text-white">{property.location}</p>
          </div>
        </div>
        <div className="p-4">
          <IoShareSocialSharp
            className="text-2xl float-end flex mb-4 cursor-pointer"
            onClick={handleShare}
          />


          <p className="font-rubik font-bold mt-2 text-2xl">Investment Amount</p>
          <div className="flex items-center text-gray-600 ">
            <MdOutlineCurrencyRupee />
            <p className="font-rubik font-bold">{property.high_returns}L</p>
          </div>

          <div className="flex items-center  text-gray-600 font-rubik">
            <span className="mr-2">Area</span>
            <span className="mr-2">{property.area} Sq.Ft.</span>
          </div>
          <p className="font-rubik font-bold mb-2 text-gray-600 text-xl">Property Type : {property.property_type}</p>
          {/* <h2 className="font-rubik text-2xl">Investment Returns</h2> */}
          <div className="items-center font-rubik">
            <div className="relative">
              <div className="mb-4 flex h-10 overflow-hidden rounded bg-gray-100 text-xs">
                <div className="flex flex-col items-center bg-black text-white" style={{ width: "15%" }}>
                  <GiReturnArrow className="text-lg mt-3" />
                </div>
                <div className="flex flex-col justify-center bg-gray-400 text-white" style={{ width: "85%" }}>
                  <span className="text-center text-sm  font-rubik">Expected Return (Annual) </span>
                  <span className="text-center text-sm font-rubik">{parseInt(property.estimated_returns_monthly)
                  }-{parseInt(property.estimated_returns_yearly)} %</span>
                </div>

              </div>
            </div>
          </div>


          <p className="font-rubik font-bold text-2xl mt-4">Overview</p>
          <p className="mt-2 text-gray-600 text-xs text-justify font-gilroy line-clamp-5">{property.overview}</p>
        </div>
      </div>


      <div className="mx-2">
        <p className="font-rubik font-bold text-2xl mb-2">Images</p>
        <div className="gallery-container">
          <ImageGallery items={galleryImages} showPlayButton={false} />
        </div>
      </div>


      <div>
        <button onClick={handlePdfOpen} className="flex items-center space-x-2 mt-3">
          <FaFilePdf size={24} className="text-red-500" />
          <span>Open PDF Documents</span>
        </button>
        {isPDFopen && (
          <iframe
            src={property.pdf}
            style={{ width: '100%', height: '100vh' }}
            frameBorder="0"
            title="PDF Viewer"
          />
        )}
      </div>

      <h2 className="font-rubik font-bold text-2xl ms-2 mt-3">360&deg; View</h2>
      <div className="mx-2">
        <video src={`https://kamaupoot.microcominternationals.com/public/${property.view_360_video}`} autoPlay controls className="border rounded-lg"></video>
      </div>




      <h2 className="font-rubik text-gray-600 mt-5 mb-2 ms-2">Monthly Cash Flow 	&#11163;</h2>
      <div className="relative mx-2">
        <div className="mb-4 flex h-12 overflow-hidden rounded bg-gray-100 text-xs">
          <div className="flex flex-col justify-center bg-red-400 text-white" style={{ width: "33.33%" }}>
            <span className="text-center text-white font-rubik">Monthly Rent</span>
            <span className="text-center text-white font-rubik">₹ 1,48,09,133</span>
          </div>
          <div className="flex flex-col justify-center bg-blue-400 text-white" style={{ width: "33.33%" }}>
            <span className="text-center text-white font-rubik">Property Management Fee</span>
            <span className="text-center text-white font-rubik">₹ 18,29,167</span>
          </div>
          <div className="flex flex-col justify-center bg-purple-400 text-white" style={{ width: "33.33%" }}>
            <span className="text-center text-white font-rubik">10% TDS</span>
            <span className="text-center text-white font-rubik">₹ 16,45,459</span>
          </div>
        </div>
      </div>


      <h2 className="text-xl font-rubik font-semibold text-black mt-5 mb-2 ms-2">Property Pricing</h2>
      <div className="mb-10">
        <PieChart />
      </div>
      <p className="font-rubik font-bold text-2xl mb-2 ms-3">Five year growth chart</p>
      <div className="mb-24 mx-3">
        <Bar data={data} options={options} />
      </div>


      <div className="w-full bottom-0 fixed  p-2 gap-2 ">
        <div className="d-flex float-end mb-2 me-4 mt-20">
          <Link to={`https://wa.me/15551234567`}>
            <img src="assets\images\wapp.png" alt="" className="w-8" />
          </Link>
        </div>
        <div>
          <button onClick={handleBookACall} className="bg-btncolor/90 text-white py-2 px-4 w-full rounded-full font-rubik text-center">Book a Call</button>
        </div>
      </div>
      <ToastContainer autoClose={500} />
    </>
  );
}

export default Propertydetail;
