import { LuBedDouble } from "react-icons/lu";
import { Link } from "react-router-dom";

function Buyahome() {
  return (
    <>

      <div className="relative mx-1 border border-gray-400 rounded-lg mt-5 ">
        <Link to="/propertydetail" className="relative inline-block w-full transform transition-transform duration-300 ease-in-out">
          <div className="rounded-lg">
            <div className="relative flex h-60 justify-center overflow-hidden rounded-lg">
              <div className="w-full transform transition-transform duration-500 ease-in-out hover:scale-110">
                <img src="assets\images\banner.jpg" alt="" />
              </div>

              {/* <div className="absolute bottom-0 mb-3 flex justify-center">
                <div className="flex space-x-5 overflow-hidden rounded-lg bg-white/70 px-4 py-1 shadow">
                  <p className="flex items-center font-medium text-gray-500"><LuBedDouble className="text-xl me-1" />4 bd</p>
                  <p className="flex items-center font-medium text-gray-500">2ba</p>
                  <p className="flex items-center font-medium text-gray-500"> 1493&sup2; m</p>
                </div>
              </div> */}
              {/* <span className="absolute left-0 top-0 z-10 ml-3 mt-3 inline-flex select-none rounded-lg bg-black opacity-50 px-3 py-2 text-sm font-medium text-white"> 360&deg; </span> */}
            </div>

            <div className="mx-2 mb-2">
              <div className="mt-4 ">
                <div className="flex items-center">
                  <div className="relative">

                    <p className="text-custom-pink text-xl font-extrabold font-serif">Recently viewed</p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </Link>
      </div>

    </>
  );

}

export default Buyahome;
