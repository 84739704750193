import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { scrollToTop } from "../utils/scrollToTop";
import { AiOutlineMail } from "react-icons/ai";
import { IoCallOutline } from "react-icons/io5";
import Footer from "../components/Footer";
import Headerb from "../components/Headerb";
import Navbar from "../components/Navbar";


function Contactus() {
  const topele = useRef(null);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    if (topele.current) {
      scrollToTop(topele);
    }
  }, []);

  useEffect(() => {
    const { firstName, lastName, email, message } = formData;
    if (firstName && lastName && email && message) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <>
      <Headerb ref={topele} />

      <section className="dark:bg-gray-900 mt-14">
        <div className="container px-6 py-12 mx-auto">
          <div>
            <p className="text-black text-3xl font-rubik font-bold dark:text-black">Contact us</p>
            <h1 className="mt-2 text-xl font-semibold text-gray-800 md:text-3xl dark:text-white font-rubik">Chat to our friendly team</h1>
            <p className="mt-3 text-gray-500 dark:text-gray-400">We’d love to hear from you. Please fill out this form or shoot us an email.</p>
          </div>

          <div className="grid grid-cols-1 gap-12 mt-10 lg:grid-cols-2">
            <div className="grid grid-cols-1 gap-12 justify-center">
              <div>
                <span className="inline-block text-white rounded-full bg-btncolor p-3">
                  <AiOutlineMail className="text-xl" />
                </span>
                <h2 className="mt-4 text-base font-medium text-gray-800 dark:text-white">Email</h2>
                <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">Our friendly team is here to help.</p>
                <Link to={`mailto:example@outlook.com`}>
                  <p className="mt-2 text-sm text-black dark:text-black">care@Kamaaupoot.in</p>
                </Link>
              </div>
              <div>
                <span className="inline-block text-white rounded-full bg-btncolor p-3">
                  <IoCallOutline className="text-xl" />
                </span>
                <h2 className="mt-4 text-base font-medium text-gray-800 dark:text-white">Phone</h2>
                <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">Mon-Fri from 8am to 5pm.</p>
                <Link to={`tel:5865248525`}>
                  <p className="mt-2 text-sm text-black dark:text-black">8440011500</p>
                </Link>
              </div>
              <div>
                <h2 className="mt-4 text-xl font-rubik text-black font-semibold dark:text-white mb-3">Follow Us :</h2>
                <div className="flex ms-2">
                  <Link to="#">
                    <img src="assets/images/facebook.png" alt="" width="25px" className="me-2 border rounded-full" />
                  </Link>
                  <Link to="#" className="text-white">
                    <img src="assets/images/instagram.png" alt="" width="30px" className="me-2 border rounded-full" />
                  </Link>
                  <Link to="#" className="text-white">
                    <img src="assets/images/pinterest.png" alt="" width="25px" className="me-2 border rounded-full" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="p-4 py-6 rounded-lg bg-gray-100 dark:bg-gray-800 md:p-8">
              <form>
                <div className="-mx-2 md:items-center md:flex">
                  <div className="flex-1 px-2">
                    <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">First Name</label>
                    <input
                      type="text"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                      placeholder="John"
                      className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 border border-gray-200 rounded-lg dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-gray-200 dark:focus:border-gray-200 focus:ring-gray-200 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                  </div>

                  <div className="flex-1 px-2 mt-4 md:mt-0">
                    <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">Last Name</label>
                    <input
                      type="text"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                      placeholder="Doe"
                      className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 border border-gray-200 rounded-lg dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-gray-200 dark:focus:border-gray-200 focus:ring-gray-200 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                  </div>
                </div>

                <div className="mt-4">
                  <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">Email address</label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="johndoe@example.com"
                    className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 border border-gray-200 rounded-lg dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-gray-200 dark:focus:border-gray-200 focus:ring-gray-200 focus:outline-none focus:ring focus:ring-opacity-40"
                  />
                </div>

                <div className="w-full mt-4">
                  <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">Message</label>
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    className="block w-full h-32 px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 border border-gray-200 rounded-lg md:h-56 dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-gray-200 dark:focus:border-gray-200 focus:ring-gray-200 focus:outline-none focus:ring focus:ring-opacity-40"
                    placeholder="Message"
                  ></textarea>
                </div>

                <button
                  type="submit"
                  disabled={!isFormValid}
                  className={`w-full px-6 py-3 mt-4 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-btncolor rounded-lg focus:outline-none focus:ring focus:ring-opacity-50 ${!isFormValid ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                >
                  Send message
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
      <Navbar />

      <Footer />
    </>
  );
}

export default Contactus;
