import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RxCross1 } from 'react-icons/rx';

function Form() {
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [responseMessage, setResponseMessage] = useState("");

  const handleClose = () => {
    navigate(-1);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Simulate a successful submission
    setResponseMessage('Request submitted successfully');
    localStorage.setItem('submittedMessage', message);
  };

  return (
    <>
      <section>
        <div className="flex-shrink-0">
          <button onClick={handleClose} className="bg-btncolor border m-2 p-2.5 rounded-full">
            <RxCross1 className="text-2xl text-white p-1" />
          </button>
        </div>
        <h1 className="text-2xl text-black font-rubik ms-3 mt-5 mb-3">Fill your requirements</h1>

        <form className="max-w-md mx-3 mt-10" onSubmit={handleSubmit}>
          <div className="grid md:grid-cols-2 md:gap-6">
            <div className="relative z-0 w-full mb-5 group">
              <label htmlFor="floating_message" className="font-sm font-rubik font-semibold">Name :</label>
              <input
                className="flex h-10 w-full rounded-md border border-black/30 bg-transparent px-3 py-2 text-sm placeholder:text-gray-600 focus:outline-none focus:ring-1 focus:ring-black/30 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                type="name"
                placeholder="Enter your name"
              />
            </div>
            <div className="relative z-0 w-full mb-5 group">
              <label htmlFor="floating_message" className="font-sm font-rubik font-semibold">Email :</label>
              <input
                className="flex h-10 w-full rounded-md border border-black/30 bg-transparent px-3 py-2 text-sm placeholder:text-gray-600 focus:outline-none focus:ring-1 focus:ring-black/30 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                type="email"
                placeholder="Email"
              />
            </div>
            <div className="relative z-0 w-full mb-5 group">
              <label htmlFor="floating_message" className="font-sm font-rubik font-semibold">Phone :</label>
              <input
                className="flex h-10 w-full rounded-md border border-black/30 bg-transparent px-3 py-2 text-sm placeholder:text-gray-600 focus:outline-none focus:ring-1 focus:ring-black/30 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                type="tel"
                placeholder="Phone no"
              />
            </div>
            <div className="relative z-0 w-full mb-5 group">
              <label htmlFor="floating_message" className="font-sm font-rubik font-semibold">Message :</label>
              <textarea
                name="floating_message"
                id="floating_message"
                className="block w-full  h-40 px-4 py-2 text-sm font-normal shadow-xs text-gray-900 bg-transparent border border-gray-300 rounded-lg placeholder-gray-400 focus:outline-none leading-relaxed resize-none"
                placeholder=" "
                required
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />

            </div>
          </div>

          <button
            type="submit"
            className="text-white bg-btncolor hover:bg-btncolor focus:ring-4 focus:outline-none focus:ring-custom-pink font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-custom-pink dark:hover:bg-custom-pink dark:focus:ring-custom-pink"
            disabled={!message}
          >
            Submit
          </button>
        </form>
        {responseMessage && <p className="text-slate-800 text-center mt-4">{responseMessage}</p>}
      </section>
    </>
  );
}

export default Form;
