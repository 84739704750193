import { useEffect, useRef } from "react";
import Footer from "../components/Footer";
import Headerb from "../components/Headerb";
import { scrollToTop } from "../utils/scrollToTop";

function Articaldetail() {
  const topele = useRef(null);
  useEffect(() => {
    if (topele.current) {
      scrollToTop(topele)
    }
  })

  return (
    <>
      <p ref={topele}>.</p>
      <Headerb />

      <div>
        <h1 className="mt-16 font-bold text-3xl font-rubik mx-3">Impact of TOD policy on Nodia,Ghaziabad</h1>
        <div className="flex ms-3 my-3">
          <img src="assets\images\wapp.png" alt="" width="20px" className="me-2" />
          <img src="assets\images\facebook.png" alt="" width="20px" className="me-2" />
          <img src="assets\images\pinterest.png" alt="" width="20px" className="me-2" />
          <img src="assets\images\twitter-icon.png" alt="" width="20px" className="me-2" />
        </div>


        <div className="mx-2">
          <img src="assets\images\banner.jpg" alt="" width="100%" className="border rounded-lg" />
          <p className="ms-2 text-xs text-gray-500">Feb 09,2024</p>
          <p className="mt-3 text-justify mb-2">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem, commodi?<span className="underline decoration-2 font-bold text-custom-pink"> www.dsndfn.com </span> Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga, debitis. Totam quod voluptates quae nam eveniet minus fugit ratione dignissimos nihil eius, error maiores tempora corrupti, nobis dolor quibusdam repellendus, labore vel laborum iste reiciendis quasi harum ex. Nobis est, amet tenetur ab quos odio deleniti, magnam eligendi officiis veniam perspiciatis doloremque non vero odit sunt libero. Asperiores aliquam cumque saepe repellendus, atque accusantium corporis hic rerum, sequi, tempora iusto.
          </p>

        </div>

      </div >

      <Footer />
    </>
  );
}

export default Articaldetail;