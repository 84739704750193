import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

function Categories() {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true); // To handle loading state
  const [error, setError] = useState(null); // To handle errors

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get('https://kamaupoot.microcominternationals.com/public/api/categories');

        // console.log("API response:", response.data); 

        if (response.data && response.data.data) {
          setCategories(response.data.data);
        } else {
          console.error("Unexpected API response format:", response.data);
          setError("Unexpected API response format.");
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
        setError('Failed to fetch categories. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  if (loading) {
    return <p className="text-center mt-10">Loading categories...</p>;
  }

  if (error) {
    return <p className="text-center mt-10">Error: {error}</p>;
  }

  if (categories.length === 0) {
    return <p className="text-center mt-10">No categories available</p>;
  }

  const handleCategoryId = (categoryId) => {
    sessionStorage.setItem('category_id', categoryId);
  };

  return (
    <>
      <h1 className="text-xl font-rubik font-bold ms-3 ">Browse More Categories</h1>
      <div className="flex flex-col m-auto p-auto mb-10">
        <div className="flex overflow-x-scroll hide-scroll-bar">
          <div className="flex flex-nowrap lg:ml-4 md:ml-4 ml-2">
            {categories.map((category) => (
              <div className="inline-block" key={category.id} onClick={() => handleCategoryId(category.id)}>
                <div className="w-24 max-w-xs overflow-hidden rounded-full duration-300 ease-in-out">
                  <Link to="/category">
                    <img
                      className="inline-block rounded-full m-2 border-slate-400 border-[1px]"
                      src={`https://kamaupoot.microcominternationals.com/public/${category.image}`}
                      alt={category.category_name || "Category"}
                      style={{ width: "60px", height: "60px" }}
                    />
                  </Link>
                </div>
                <p className="text-center text-xs me-5 font-semibold">
                  {category.category_name || "Unnamed"}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Categories;
