import { useEffect, useState } from 'react';
import axios from 'axios';
import { IoLocationOutline } from "react-icons/io5";
import { MdCurrencyRupee } from "react-icons/md";
import { Link } from "react-router-dom";

function Upcomingproject() {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    axios.get('https://kamaupoot.microcominternationals.com/public/api/properties')
      .then(response => {
        const data = response.data;
        if (data && Array.isArray(data.properties)) {
          setProjects(data.properties);
        } else {
          console.error('Expected an array of properties but got:', typeof data.properties);
        }
      })
      .catch(error => {
        console.error('Error fetching the properties:', error);
      });
  }, []);

  const handleViewProperty = (propertyId) => {
    sessionStorage.setItem('property_id', propertyId);
  };

  return (
    <>
      <h1 className="text-xl font-rubik font-bold ms-3 mt-5">Upcoming Projects</h1>
      <p className="text-sm font-gilroy text-gray-400 ms-3 mb-2">Visit these projects and get early bird benefits</p>
      <div className="flex flex-col m-auto p-auto ">
        <div className="flex overflow-x-scroll pb-10 hide-scroll-bar">
          <div className="flex flex-nowrap lg:ml-6 md:ml-4 ml-2 ">

            {projects.length > 0 ? (
              projects.map((project, index) => (
                <div key={index} className="inline-block pe-3">
                  <div className="max-w-xs overflow-hidden rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300 ease-in-out">
                    <div className="w-[300px] rounded-md border">
                      {/* Check if images exist and the array is not empty */}
                      {project.images && project.images.length > 0 ? (
                        <img
                          src={`https://kamaupoot.microcominternationals.com/public/${project.images[project.images.length - 1]?.images}`}
                          alt={project.title}
                          className="h-[200px] w-full rounded-md object-cover"
                        />
                      ) : (
                        <div className="h-[200px] w-full rounded-md bg-gray-200 flex items-center justify-center">
                          <p className="text-gray-500">No Image Available</p>
                        </div>
                      )}
                      <div className="p-4">
                        <h1 className="text-xl font-semibold font-rubik line-clamp-1">{project.title}</h1>
                        <h4 className="text-base text-gray-600 font-semibold font-rubik line-clamp-1">Property type : {project.property_type}</h4>
                        <div className="mt-1 flex items-center text-sm text-gray-600">
                          <IoLocationOutline className="mr-1 text-lg" />
                          <p className="text-sm">{project.location}</p>
                        </div>
                        <div className="flex items-center text-sm text-gray-600">
                          <MdCurrencyRupee className="mr-1 text-custom-pink text-lg" />
                          <p className="text-custom-pink text-lg">{project.high_returns} L</p>
                        </div>
                        <p className="text-sm text-gray-600">{project.area} Sq. Ft.</p>
                        <div className="flex items-center text-sm text-gray-600">
                          <p className="text-custom-pink text-sm font-extrabold font-serif">Expected Return (Annual) : {parseInt(project.estimated_returns_monthly)}-{parseInt(project.estimated_returns_yearly)}%</p>
                        </div>
                      </div>

                      <Link to={`/propertydetail`} onClick={() => handleViewProperty(project.id)}>
                        <div className="flex items-center justify-center bg-btncolor/90 p-4 border-t space-x-2">
                          <button className="ml-1 text-white font-rubik font-bold">View Property</button>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-center text-gray-500">No projects available.</p>
            )}

          </div>
        </div>
      </div>
    </>
  );
}

export default Upcomingproject;
