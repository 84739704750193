import axios from 'axios';
import { IoLocationOutline } from "react-icons/io5";
import { MdCurrencyRupee } from "react-icons/md";
import { Link } from "react-router-dom";
import Headerb from "../components/Headerb";
import { scrollToTop } from "../utils/scrollToTop";
import { useEffect, useRef, useState } from "react";
import Footer from "../components/Footer";
import { MdOutlineTimer } from "react-icons/md";

function Categoriesproperty() {
  const [properties, setProperties] = useState([]);
  const [timer, setTimer] = useState(7 * 24 * 60 * 60); // 7 days in seconds
  const [isSold, setIsSold] = useState(false);
  const topele = useRef(null);
  const [isTimerActive, setIsTimerActive] = useState(true);

  useEffect(() => {
    if (topele.current) {
      scrollToTop(topele);
    }

    const fetchProperties = async (categoryId) => {
      try {
        const response = await axios.get(`https://kamaupoot.microcominternationals.com/public/api/property-category/${categoryId}`);
        if (response.data && Array.isArray(response.data.properties)) {
          setProperties(response.data.properties);
        } else if (response.data && response.data.message) {
          console.error("API Message:", response.data.message);
        } else {
          console.error("No properties found in the API response.");
        }
      } catch (error) {
        console.error("Error fetching properties:", error);
      }
    };


    // console.log(properties)

    // Initial fetch based on the category_id in sessionStorage
    const category_id = sessionStorage.getItem('category_id');
    if (category_id && !isSold) {
      fetchProperties(category_id);
    }

    // Timer countdown
    const countdown = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 1) {
          clearInterval(countdown);
          setIsSold(true);
          fetchProperties(6); // Fetch properties with category_id 6 when the timer ends
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);

    return () => clearInterval(countdown);
  }, [isSold]);

  const formatTime = (seconds) => {
    const days = Math.floor(seconds / (24 * 60 * 60));
    const hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60));
    const minutes = Math.floor((seconds % (60 * 60)) / 60);
    const sec = seconds % 60;

    return `${days}d ${hours}h ${minutes}m ${sec}s`;
  };

  const handleViewProperty = (propertyId) => {
    sessionStorage.setItem('property_id', propertyId);
  };

  return (
    <>
      <p ref={topele}>.</p>
      <Headerb />
      <h1 className="text-2xl font-bold font-rubik mt-16 ms-3">
        Properties
      </h1>
      <div className="mb-10">
        {properties.length > 0 ? properties.map(property => (
          <div className="mx-1 mt-3" key={property.id}>
            <div className="w-full rounded border">
              <img src={`https://kamaupoot.microcominternationals.com/public/${property.images[property.images.length - 1]?.images}`} alt="Property" className="h-[200px] w-full rounded-md object-cover" />
              <div className="p-4">
                <h1 className="text-xl font-semibold font-rubik line-clamp-1">{property.title}</h1>
                <h1 className="text-lg font-semibold text-gray-600 font-rubik line-clamp-1">Property type : {property.property_type}</h1>

                <div className="flex items-center text-sm text-gray-600 font-bold">
                  <IoLocationOutline className="mr-1 mt-1 text-lg font-bold" />
                  <p className="text-base">{property.location} </p>
                </div>

                <div className="flex items-center text-sm text-gray-600">
                  <MdCurrencyRupee className="mr-1 text-custom-pink text-lg" />
                  <p className="text-custom-pink text-lg">{property.high_returns} L</p>
                </div>
                <div className="flex items-center text-sm text-gray-600">
                  <p className="text-custom-pink font-gilroy font-bold text-base">Area : {property.area} Sq. Ft.</p>
                </div>
                <div className="flex items-center text-sm text-gray-600">
                  <p className="text-custom-pink text-lg font-extrabold font-serif">Expected Return (Annual) :{parseInt(property.estimated_returns_monthly)}-{parseInt(property.estimated_returns_yearly)}%</p>
                </div>

                <div className="mt-1 flex items-center text-sm text-gray-600 font-bold">
                  <p className="text-lg">{isTimerActive && sessionStorage.getItem('category_id') === '6' ?
                    `Time left: ${formatTime(timer)}` :
                    isSold ? "Sold" : ""}</p>
                </div>
              </div>
              <Link to={`/propertydetail`} onClick={() => handleViewProperty(property.id)}>
                <div className="flex items-center justify-center bg-btncolor p-4 border-t space-x-2">
                  <button className="ml-1 text-white font-rubik font-bold">View Property</button>
                </div>
              </Link>
            </div>
          </div>
        )) : (
          <p className="text-center text-gray-600">Data not fonund...</p>
        )}
      </div>
      <Footer />
    </>
  );
}

export default Categoriesproperty;
