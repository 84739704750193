import React, { useState } from 'react';
import axios from 'axios';
import { IoLocation } from "react-icons/io5";
import { IoIosArrowDown } from "react-icons/io";
import { Link } from 'react-router-dom';
import { FaLocationCrosshairs } from "react-icons/fa6";
import { VscLocation } from "react-icons/vsc";

const SearchBox = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [city, setCity] = useState('');
  const [cityData, setCityData] = useState([]); // Initialize cityData as an empty array

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handleInputChange = (event) => {
    setCity(event.target.value);
  };

  const handleKeyDown = async (event) => {
    if (event.key === 'Enter') {
      const token = sessionStorage.getItem('token');
      try {
        const response = await axios.get(
          `https://kamaupoot.microcominternationals.com/public/api/property-city/${city}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data && Array.isArray(response.data.properties)) {
          setCityData(response.data.properties);
        } else {
          setCityData([]);
          console.error('Unexpected API response:', response.data);
        }
      } catch (error) {
        console.error('Error fetching city data:', error);
      }
    }
  };

  const handleViewProperty = (propertyId) => {
    sessionStorage.setItem('property_id', propertyId);
  };

  return (
    <>
      {/* First div */}
      <div className="bg-white flex items-center justify-between rounded-full p-2 mt-20 mx-1" onClick={togglePopup}>
        <div className='flex items-center'>
          <div>
            <IoLocation className='text-btncolor text-3xl' />
          </div>
          <div className='ms-1'>
            <div className='flex items-center'>
              <p className='text-lg text-btncolor font-extrabold font-rubik'>{city ? city : 'Jaipur'}</p>
              <span><IoIosArrowDown /></span>
            </div>
          </div>
        </div>
      </div>

      {/* Second div (popup) */}
      {isPopupOpen && (
        <div className="fixed inset-0 bg-gray-100 p-4 min-h-screen z-50">
          {/* Header */}
          <div className="flex items-center mb-4">
            <button className="text-xl" onClick={togglePopup}><IoIosArrowDown /></button>
            <h1 className="text-lg font-semibold ml-4">Select a location</h1>
          </div>

          {/* Search Input */}
          <div className="mb-4">
            <input
              type="text"
              placeholder="Search for area, street name..."
              className="w-full p-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-btncolor"
              value={city}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
            />
          </div>

          {/* Nearby Locations */}
          <h2 className="text-gray-500 text-sm font-semibold mb-2">Nearby Locations</h2>
          <div className="space-y-3 overflow-auto h-full">
            {cityData.length > 0 ? (
              cityData.map((location, index) => (
                <div key={index} className="bg-white p-4 rounded-lg shadow-sm flex items-center">
                  <div className="flex items-center text-btncolor">
                    <VscLocation className="text-xl w-8 h-8" />
                  </div>
                  <Link to={`/propertydetail`} onClick={() => handleViewProperty(location.id)}>
                    <div className="">
                      <span className="ml-2 font-semibold">{location.title}</span>
                      <span className="ml-2 block text-sm text-gray-600">{location.location}</span>
                      <span className="ml-2 text-xs text-gray-500">{location.area} sq ft</span>
                    </div>
                  </Link>
                </div>
              ))
            ) : (
              <p className="text-center text-gray-500 mt-4">Data not found.</p>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default SearchBox;
